import React from 'react';
import ContactForm from '../components/ContactForm';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../assets/styles/main.css';


function VistaContacto() {
  return (
    <div className="bg_cont">
      <Header />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default VistaContacto;
