import React from 'react';
import '../assets/styles/CardComponent.css';

const Romboide2 = ({content, content2, content3, content4}) => {
  return (
    <div className="romboide2">
      <p>{content}</p>
      <p>{content2}</p>
      <p>{content3}</p>
      <p>{content4}</p>
    </div>
  );
};

export default Romboide2;

