import React from 'react';
import '../assets/styles/AboutUs.css';

const Herramientas = () => {
    return (
        <section id="about" className="about-us">
            <h1 className="title">Herramientas</h1>
            <div className="section">
                <h2>Descargas</h2>
                <p>
                    <a href="/files/PDS.pdf" download="manual.pdf" style={{ textDecoration: 'none', color: '#6f1f2d' }}>
                        *Descargar pdf
                    </a>
                </p>
            </div>
        </section>
    );
}
export default Herramientas;
