import React from 'react';
import '../assets/styles/VideoCarousel.css';
import introVideo from '../assets/videos/intro.mp4'; // Importa el video directamente

function VideoCarousel() {
  return (
    <div className="video-carousel">
      <video autoPlay loop muted>
        <source src={introVideo} type="video/mp4" />
        Tu navegador no soporta el elemento video.
      </video>
    </div>
  );
}

export default VideoCarousel;
