import React from "react";
import Romboide from "./Romboide";
import RomboideBack from "./RomboideBack";
import RomboideImagen from "./RomboideImagen";
import Romboide2 from "./romboide2";

const RomboideLayout = ({ 
  romboideContent, 
  romboideBackWidth, 
  romboideBackHeight, 
  romboideImageUrl, 
  romboide2Content 
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "30vh", // Ocupa toda la altura de la ventana
        position: "relative",
      }}
    >
      <div style={{ position: "relative" }}>
        {/* Romboide */}
        <Romboide content={romboideContent.content1} content2={romboideContent.content2} />
        
        {/* Romboide de fondo */}
        <RomboideBack width={romboideBackWidth} height={romboideBackHeight} />
        
        {/* Imagen dentro del romboide */}
        <RomboideImagen imageUrl={romboideImageUrl} />
        
        {/* Romboide 2 */}
        <Romboide2 
          content={romboide2Content.content1} 
          content2={romboide2Content.content2} 
          content3={romboide2Content.content3} 
          content4={romboide2Content.content4} 
        />
      </div>
    </div>
  );
};

export default RomboideLayout;
