// Footer.js
import React from 'react';
import { FaWhatsapp, FaPhone, FaEnvelope } from 'react-icons/fa';
import '../assets/styles/Footer.css';


function Footer() {
  return (
    <div id= "interference">
    <footer className="custom-footer">
      <div className="footer-content">
        <div className="contact-info">
          <FaWhatsapp className="icon" />
          <FaPhone className="icon" />
          <span className="contact-numbers">8863 - 4402 / 7081 - 0116 / 8359 - 7729</span>
        </div>
        <div className="email-info">
          <FaEnvelope className="icon" />
          <span className="email-text">info@automatecr.com</span>
        </div>
      </div>
      <svg style={{ visibility: 'hidden', position: 'absolute' }} width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="butonB">
            <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur" /> {/* Valor reducido */}
            <feColorMatrix in="blur" mode="matrix" values="1 1 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -10" result="butonB" />
            <feComposite in="SourceGraphic" in2="butonB" operator="atop" />
          </filter>
        </defs>
      </svg>
    </footer>
    </div>
  );
}

export default Footer;
