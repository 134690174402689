import React from "react";
import "../assets/styles/Modelado3D.css";
import TitleServices from "./TitleServices";
import SectionWithImageAndList from "./SectionWithImageAndList";
import image from "../../src/assets/images/auto.png"; // Actualiza con la ruta de la primera imagen
import image2 from "../assets/images/auto2.png"
import image3 from "../assets/images/auto3.png"; // Reemplaza con la ruta de la imagen correcta
import SectionImageLeftTextRight from "./SectionImageLeftTextRight";

const Automatizaciones = () => {
    const points = [
        "Integración de sistemas de control: Implementamos y conectamos distintos sistemas de control, asegurando que todos los componentes de su proceso industrial trabajen de manera armoniosa y eficiente. Esto incluye la integración de PLCs, HMIs, y sistemas SCADA, optimizando el monitoreo y control de sus operaciones.",
        "Automatización de procesos industriales: Diseñamos e implementamos soluciones de automatización que eliminan la necesidad de intervención manual en procesos repetitivos o complejos. Esto permite que su empresa funcione de manera más autónoma, con un mayor grado de control sobre cada etapa de producción.",
        "Soluciones personalizadas: Entendemos que cada industria y cada proceso es único. Por eso, nuestras integraciones y automatizaciones se diseñan a medida, adaptándose perfectamente a las necesidades específicas de cada cliente. Desde líneas de producción hasta sistemas de ensamblaje, garantizamos que nuestras soluciones se ajusten a su flujo de trabajo.",
        "Optimización de eficiencia operativa: Nuestras soluciones de automatización están diseñadas para maximizar la eficiencia en cada fase de sus operaciones. Al reducir la intervención manual, minimizamos el margen de error y mejoramos la consistencia en la producción, lo que se traduce en menos desperdicio y un mayor rendimiento.",
        "Mayor autonomía y control: Las integraciones que realizamos permiten que sus sistemas funcionen de manera autónoma con una supervisión mínima, mientras que las automatizaciones aumentan la capacidad de producción sin necesidad de añadir mano de obra adicional. Esto mejora la escalabilidad de su empresa y le permite adaptarse rápidamente a las demandas del mercado."
    ]

    const points2 = [
        "Aumento de la eficiencia operativa al automatizar procesos clave.",
        "Reducción de costos laborales y de producción, mejorando la rentabilidad.",
        "Mayor consistencia en la calidad de los productos al reducir la intervención humana.",
        "Control centralizado de sus operaciones mediante sistemas integrados de control.",
        "Flexibilidad para escalar y ajustar sus operaciones según las necesidades del negocio."
    ]
    return (
        <div style={{ marginTop: "120px" }}>
            <div>
                <TitleServices
                    title="Automatizaciones"
                    text="Nos especializamos en integrar equipos de control y sistemas mecatrónicos avanzados para automatizar procesos industriales de manera eficiente. Al combinar tecnologías de control con soluciones de automatización de última generación, ayudamos a las empresas a aumentar la autonomía de sus operaciones, mejorar la precisión y reducir costos operativos. Nuestro enfoque está orientado a brindar soluciones integrales que optimicen la productividad y la eficiencia en todos los niveles."
                    image={image}
                />
            </div>
            <SectionWithImageAndList
                title="¿Qué ofrecemos?"
                points={points}
                image={image2}
            />
            <SectionImageLeftTextRight
                title="Beneficios para su empresa"
                points={points2}
                image={image3}
            />
        </div>


    );
};
export default Automatizaciones;