// VistaAbout.js
import React from 'react';
import '../assets/styles/main.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AboutUs from "../components/AboutUs";


function VistaAbout() {
  return (
    <div className="bg_cont">
      <Header />
      <AboutUs />
      <Footer />
    </div>
  );
}

export default VistaAbout;
