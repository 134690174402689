// VistaPrincipal.js
import React from 'react';
import '../assets/styles/main.css';
import Header from '../components/Header';
import MainContent from '../components/MainContent';
import Footer from '../components/Footer';
import VideoCarousel from '../components/VideoCarousel';
import Aditionalinfo from '../components/aditionalinfo';
import ImageCarousel from '../components/ImageCarouselv2';
import ContactButtom from '../components/ContactButtom';

function VistaPrincipal() {
  return (
    <div className="bg_cont">
      <Header />
      <VideoCarousel /> 
      <MainContent />
      <Aditionalinfo />
      <ContactButtom />
      <ImageCarousel />
      <Footer />
    </div>
  );
}

export default VistaPrincipal;
