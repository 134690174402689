import React from "react";
import "../assets/styles/Modelado3D.css";
import TitleServices from "./TitleServices";
import SectionWithImageAndList from "./SectionWithImageAndList";
import image from "../../src/assets/images/limpieza.png"; // Actualiza con la ruta de la primera imagen
import image2 from "../assets/images/limpieza1.png"
import image3 from "../assets/images/limpieza2.png"; // Reemplaza con la ruta de la imagen correcta
import SectionImageLeftTextRight from "./SectionImageLeftTextRight";

const LimpiezayOrden = () => {
    const points = [
        "Diseño estructurado y eficiente: Organizamos los componentes eléctricos dentro del tablero de manera lógica y accesible, lo que no solo garantiza un funcionamiento óptimo sino que también facilita la identificación de piezas clave durante las revisiones.",
        "Etiquetado claro y preciso: Cada componente y cableado se identifica adecuadamente mediante un sistema de etiquetado estandarizado. Esto asegura que el personal de mantenimiento pueda realizar intervenciones de manera rápida y sin confusiones.",
        "Cumplimiento de normativas: Nos aseguramos de que nuestros tableros cumplan con las normativas vigentes de seguridad y eficiencia, brindando a su equipo la tranquilidad de trabajar en instalaciones seguras y correctamente organizadas.",
        "Reducción de tiempos de inactividad: La limpieza y el orden permiten identificar problemas con mayor rapidez y eficacia, reduciendo significativamente los tiempos de inactividad durante mantenimientos o fallas inesperadas.",
        "Mayor vida útil de los componentes: Un entorno organizado y limpio minimiza la exposición a contaminantes y polvo, prolongando la vida útil de los componentes eléctricos y manteniendo su rendimiento."
    ];
    const points2 = [
        "Mantenimientos más rápidos y seguros.",
        "Menor probabilidad de errores durante las intervenciones.",
        "Mayor facilidad para futuras expansiones o modificaciones en los sistemas eléctricos.",
        "Mejora de la seguridad operativa al reducir riesgos de corto circuito o mal funcionamiento por falta de orden."
    ]
    return (
        <div style={{ marginTop: "120px" }}>
            <div>
                <TitleServices
                    title="Limpieza y orden de gabinetes"
                    text="En el mundo industrial, la organización y limpieza de los tableros eléctricos no es solo una cuestión estética; es una necesidad operativa que puede marcar la diferencia entre una intervención rápida y una paralización innecesaria. Un tablero eléctrico bien organizado facilita el acceso a los componentes, agiliza los mantenimientos y reduce el riesgo de errores humanos durante las intervenciones."
                    image={image}
                />
            </div>
            <SectionWithImageAndList
                title="¿Qué ofrecemos?"
                points={points}
                image={image2}
            />
            <SectionImageLeftTextRight
                title="Beneficios para su empresa"
                points={points2}
                image={image3}
            />
        </div>


    );
};
export default LimpiezayOrden;