import React from 'react';
import '../assets/styles/CardComponent.css';

const Romboide = ({content, content2}) => {
  return (
    <div className="romboide">
      <p>{content}</p>
      <p>{content2}</p>
    </div>
  );
};

export default Romboide;

