const RomboideImagen = ({ imageUrl }) => {
    return (
      <div className="romboide-contenedor">
        <div
          className="romboide-imagen"
          style={{ backgroundImage: `url(${imageUrl})` }}
        ></div>
        <svg style={{ visibility: 'hidden', position: 'absolute' }} width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="cardInfo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="40" result="blur" />
            <feColorMatrix
              in="blur"
              type="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -10"
              result="cardInfo"
            />
            <feComposite in="SourceGraphic" in2="cardInfo" operator="atop" />
          </filter>
        </defs>
      </svg>

      </div>
      
    );
  };
  
  export default RomboideImagen;
  