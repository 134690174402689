import React from "react";
import "../assets/styles/Modelado3D.css";
import TitleServices from "./TitleServices";
import SectionWithImageAndList from "./SectionWithImageAndList";
import image from "../../src/assets/images/migra.png"; // Actualiza con la ruta de la primera imagen
import image2 from "../assets/images/migra1.png"
import image3 from "../assets/images/migra2.png"; // Reemplaza con la ruta de la imagen correcta
import SectionImageLeftTextRight from "./SectionImageLeftTextRight";

const Migraciones = () => {
    const points = [
        "Evaluación completa del sistema: Realizamos un análisis exhaustivo de los equipos y sistemas actuales para identificar las áreas críticas que requieren actualización. Esto incluye la evaluación de componentes obsoletos, software desactualizado, y tecnologías que ya no ofrecen el rendimiento o soporte técnico necesario.",
        "Actualización a nuevas tecnologías: Migramos sus equipos a versiones más recientes y eficientes, aprovechando los avances tecnológicos para mejorar la velocidad, seguridad y capacidad de los sistemas. Esto incluye tanto hardware como software, asegurando que todos los aspectos del sistema estén optimizados para el futuro.",
        "Minimización de tiempos de inactividad: Entendemos que cualquier actualización puede interrumpir las operaciones, por lo que planificamos cada migración cuidadosamente para minimizar los tiempos de inactividad. Nuestra experiencia nos permite realizar migraciones de manera eficiente y segura, asegurando que su empresa retome sus operaciones lo antes posible.",
        "Integración con sistemas existentes: Aseguramos que los nuevos equipos y tecnologías se integren perfectamente con los sistemas que su empresa ya tiene en funcionamiento. Esto garantiza una transición fluida, evitando problemas de compatibilidad y maximizando la eficiencia general del sistema.",
        "Cumplimiento normativo: Nos aseguramos de que todos los equipos y sistemas actualizados cumplan con las normativas y estándares de seguridad actuales, brindando a su empresa la tranquilidad de operar bajo las regulaciones vigentes y con equipos certificados.",   
    ]
    const points2 = [
        "Mejora en la eficiencia operativa al adoptar tecnologías modernas.",
        "Reducción de costos operativos y energéticos gracias a equipos más eficientes.",
        "Mayor seguridad y confiabilidad en los sistemas, reduciendo el riesgo de fallos.",
        "Incremento de la competitividad al estar a la vanguardia tecnológica en su sector.",
        "Compatibilidad total con los últimos estándares y regulaciones industriales."
    ]
    return (
        <div style={{ marginTop: "120px" }}>
            <div>
                <TitleServices
                    title="Migraciones"
                    text="En el mundo industrial, los avances tecnológicos son continuos y es fundamental mantener los sistemas actualizados para garantizar un rendimiento óptimo y competitivo. Ofrecemos servicios de migración para actualizar sus equipos obsoletos, permitiendo a su empresa beneficiarse de las últimas innovaciones tecnológicas. Esto no solo mejora la eficiencia operativa, sino que también asegura mayor confiabilidad y compatibilidad con las normativas actuales."
                    image={image}
                />
            </div>
            <SectionWithImageAndList
                title="¿Qué ofrecemos?"
                points={points}
                image={image2}
            />
            <SectionImageLeftTextRight
                title="Beneficios para su empresa"
                points={points2}
                image={image3}
            />
        </div>


    );
};
export default Migraciones;