import React from 'react';
import '../assets/styles/CardComponent.css';

// Componente sin texto
const RomboideBack = ({ width = "200px", height = "100px", backgroundColor = "#1c202b" }) => {
  return (
    <div
      className="romboideBack"
      style={{
        width: width,
        height: height,
        backgroundColor: backgroundColor,
      }}
    ></div>
  );
};

export default RomboideBack;