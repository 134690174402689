import React from "react";
import "../assets/styles/SectionImageLeftTextRight.css";

const SectionImageLeftTextRight = ({ title, points, image }) => {
  return (
    <div className="section-image-left-text-right-container">
      <div className="text-content">
        <h2>{title}</h2>
        <ul>
          {points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
      <img src={image} alt="Ejemplo de modelado en 3D" className="section-image" />
    </div>
  );
};

export default SectionImageLeftTextRight;
