import React from "react";
import "../assets/styles/Modelado3D.css";
import TitleServices from "./TitleServices";
import SectionWithImageAndList from "./SectionWithImageAndList";
import image from "../../src/assets/images/repo.png"; // Actualiza con la ruta de la primera imagen
import image2 from "../assets/images/repo1.png"
import image3 from "../assets/images/repo2.png"; // Reemplaza con la ruta de la imagen correcta
import SectionImageLeftTextRight from "./SectionImageLeftTextRight";

const Repositorios = () => {
    const points = [
        "Bibliotecas de procesos predefinidos: Disponemos de una amplia gama de repositorios con procesos estándar utilizados en diversas industrias. Esto incluye bloques de programación PLC y módulos de interfaz HMI que ya han sido probados y optimizados para un funcionamiento eficiente. Reutilizar estos componentes permite reducir drásticamente el tiempo de desarrollo y asegurar resultados confiables.",
        "Adaptación a necesidades específicas: Aunque contamos con procesos predefinidos, todos nuestros repositorios pueden ser personalizados según las necesidades específicas de cada cliente. Esto permite ajustar las soluciones de automatización a las particularidades de su industria o aplicación sin tener que empezar desde cero.",
        "Actualización continua: Nuestros repositorios se mantienen actualizados con las últimas tecnologías y estándares de la industria. Esto asegura que nuestros clientes siempre estén trabajando con soluciones modernas, escalables y que cumplen con las normativas más recientes."
    ];
    const points2 = [
        "Implementación más rápida y eficiente de sistemas de automatización.",
        "Reducción de costos al utilizar soluciones predefinidas y optimizadas.",
        "Mayor confiabilidad y estabilidad en los procesos, gracias a bloques de programación probados.",
        "Flexibilidad para personalizar y adaptar los procesos a las necesidades específicas de su empresa."
    ]
    return (
        <div style={{ marginTop: "120px" }}>
            <div>
                <TitleServices
                    title="Repositorios"
                    text="Entendemos la importancia de la rapidez y eficiencia en la programación y diseño de sistemas automatizados. Para ello, contamos con repositorios de procesos comunes en la industria que permiten reducir significativamente el tiempo de desarrollo de lógica PLC y diseño de HMI. Esto garantiza una implementación más rápida y confiable de los sistemas automatizados, aprovechando soluciones probadas y optimizadas."
                    image={image}
                />
            </div>
            <SectionWithImageAndList
                title="¿Qué ofrecemos?"
                points={points}
                image={image2}
            />
            <SectionImageLeftTextRight
                title="Beneficios para su empresa"
                points={points2}
                image={image3}
            />
        </div>


    );
};
export default Repositorios;