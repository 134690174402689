import React from 'react';
import '../assets/styles/main.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DiagramasElectricos from "../components/DiagramasElectricos";


function DiagramasElectricospage() {
  return (
    <div className="bg_cont">
      <Header />
      <DiagramasElectricos />
      <Footer />
    </div>
  );
}

export default DiagramasElectricospage;