import React from 'react';
import '../assets/styles/aditionalinfo.css';

function AditionalInfo() {
  return (
    <div className="info-container">
      <div className="lineas-container">
        <div className="linea-roja"></div>
        <div className="linea-roja"></div>
      </div>
      <div className="texto-box">
        <p className="texto">
          En AUTOMATE nos especializamos en la automatización de procesos industriales, brindando soluciones diseñadas para aumentar la productividad y reducir los tiempos de inactividad. Con un enfoque en la personalización y la tecnología de vanguardia, nos aseguramos de que tu empresa alcance nuevos niveles de eficiencia operativa.
        </p>
      </div>
    </div>
  );
}

export default AditionalInfo;
