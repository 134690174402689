import React from "react";
import "./assets/styles/main.css";
import VistaPrincipal from "./pages/VistaPrincipal.js";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import VistaAbout from "./pages/VistaAbout.js";
import  VistaContacto from "./pages/VistaContacto.js";
import VistaServices from "./pages/VistaServices.js";
import LimpiezaGabinetespage from "./pages/LimpiezaGabinetespage.js";
import Automatizacionespage from "./pages/Automatizacionespage.js";
import DiagramasElectricospage from "./pages/DiagramasElectricospage.js";
import Migracionespage from "./pages/Migracionespage.js";
import Modelado3Dpage from "./pages/Modelado3Dpage.js";
import Repositoriospage from "./pages/Repositoriospage.js";
import Herramientaspage from "./pages/Herramientaspage.js";

function App() {
  return (
    <div class="bg_cont">
      <Router>
        <Routes>
          <Route path='/' element={<VistaPrincipal/>} />
          <Route path= '/aboutus' element= {<VistaAbout/>} />
          <Route path='/contacto' element={<VistaContacto/>} />
          <Route path= '/services' element={<VistaServices/>} />
          <Route path='/limpieza' element={<LimpiezaGabinetespage/>} />
          <Route path= '/automatizaciones' element={<Automatizacionespage/>}/>
          <Route path= '/diagramasE' element={<DiagramasElectricospage/>}/>
          <Route path= '/migraciones' element={<Migracionespage/>}/>
          <Route path= '/modelado3d' element={<Modelado3Dpage/>}/>
          <Route path= '/repositorios' element={<Repositoriospage/>}/>
          <Route path="/herramientas" element={<Herramientaspage/>} />
          <Route path="*" element={<VistaPrincipal />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
