import React from "react";
import "../assets/styles/Modelado3D.css";
import TitleServices from "./TitleServices";
import SectionWithImageAndList from "./SectionWithImageAndList";
import image from "../../src/assets/images/diagrama.png"; // Actualiza con la ruta de la primera imagen
import image2 from "../assets/images/diagrama1.png"
import image3 from "../assets/images/diagrama2.png"; // Reemplaza con la ruta de la imagen correcta
import SectionImageLeftTextRight from "./SectionImageLeftTextRight";

const DiagramasElectricos = () => {
    const points = [
        "Precisión y claridad: Nuestros diagramas eléctricos se crean con un enfoque en la exactitud, utilizando símbolos estandarizados y nomenclaturas claras. Esto permite que cualquier técnico o ingeniero pueda interpretar el esquema, facilitando la comprensión de los sistemas.",
        "Diseño detallado: Cada diagrama incluye todos los componentes eléctricos, conexiones, y rutas de cableado, garantizando que el proyecto esté completamente documentado. Esto es crucial para la instalación inicial, pero también para cualquier futura modificación o expansión del sistema.",
        "Optimización para el mantenimiento: Al contar con un diagrama eléctrico detallado, las intervenciones de mantenimiento y resolución de problemas se realizan de manera más rápida y efectiva. Los técnicos pueden identificar fácilmente componentes específicos, circuitos y rutas de energía, reduciendo tiempos de diagnóstico y minimizando los tiempos de inactividad."
    ];
    const points2 = [
        "Mejora en la precisión y eficiencia de la instalación de sistemas eléctricos.",

        "Mantenimiento más rápido y sencillo gracias a una documentación clara.",

        "Mayor seguridad al asegurar que todos los sistemas eléctricos estén correctamente configurados y documentados.",

        "Fácil adaptación y expansión de los sistemas existentes con documentación accesible y comprensible."

    ]
    return (
        <div style={{ marginTop: "120px" }}>
            <div>
                <TitleServices
                    title="Diagramas eléctricos"
                    text="En cualquier proyecto industrial, los diagramas eléctricos son fundamentales para garantizar una instalación, mantenimiento y operación eficientes. Un diagrama bien elaborado no solo facilita el proceso de instalación, sino que también sirve como una herramienta clave para diagnosticar y resolver problemas, realizar mejoras y asegurar el buen funcionamiento de los sistemas eléctricos."
                    image={image}
                />
            </div>
            <SectionWithImageAndList
                title="¿Qué ofrecemos?"
                points={points}
                image={image2}
            />
            <SectionImageLeftTextRight
                title="Beneficios para su empresa"
                points={points2}
                image={image3}
            />
        </div>


    );
};
export default DiagramasElectricos;