import React from 'react';
import '../assets/styles/main.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Modelado3D from "../components/Modelado3D";


function Modelado3Dpage() {
  return (
    <div className="bg_cont">
      <Header />
      <Modelado3D />
      <Footer />
    </div>
  );
}

export default Modelado3Dpage;