import React from 'react';
import '../assets/styles/main.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LimpiezayOrden from "../components/LimpiezayOrden";


function LimpiezaGabinetespage() {
  return (
    <div className="bg_cont">
      <Header />
      <LimpiezayOrden />
      <Footer />
    </div>
  );
}

export default LimpiezaGabinetespage;