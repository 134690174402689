import React from "react";
import "../assets/styles/Modelado3D.css";
import image from "../../src/assets/images/caja.png"; // Actualiza con la ruta de la primera imagen
import image2 from "../assets/images/caja2.png"
import image3 from "../assets/images/caja3.png"; // Reemplaza con la ruta de la imagen correcta
import TitleServices from "./TitleServices";
import SectionWithImageAndList from "./SectionWithImageAndList";
import SectionImageLeftTextRight from "./SectionImageLeftTextRight";

const HeaderModelado3D = () => {
  const points = [
    "Visualización realista: Con el modelado en 3D, proporcionamos una representación visual detallada del proyecto completo, permitiendo al cliente ver cómo se organizarán los componentes, equipos y tableros antes de la implementación. Esto facilita la toma de decisiones informadas y permite ajustar el diseño antes de la fase de ejecución.",
    "Optimización del diseño: Gracias al modelado en 3D, podemos identificar posibles mejoras o ajustes en el diseño que optimicen el uso del espacio, la eficiencia de los sistemas, y la accesibilidad para el mantenimiento. Esto ayuda a evitar problemas durante la instalación y asegura un proceso más fluido.",
    "Presentación profesional: El modelado en 3D también es una excelente herramienta de presentación para mostrar a los interesados, inversores o partes clave del proyecto cómo se verá el sistema una vez completado. Esto ayuda a generar confianza y respaldo en las decisiones de diseño.",
    "Facilitación de aprobaciones y planificación: Al contar con un modelo tridimensional detallado, los equipos de planificación y gestión pueden visualizar de manera más clara cómo encajarán los sistemas en el espacio físico, facilitando la aprobación de proyectos por parte de la gerencia y otros actores clave."
  ];
  const points2 = [
    "Mejor comprensión del proyecto desde el inicio, lo que minimiza los cambios durante la fase de ejecución.",
    "Identificación temprana de posibles problemas o mejoras en el diseño, optimizando los tiempos de instalación y reduciendo costos adicionales.",
    "Capacidad de prever el espacio y los recursos necesarios, evitando sorpresas durante la ejecución del proyecto.",
    "Presentaciones profesionales que brindan a los interesados una visión clara y convincente del resultado final."
  ]
  return (
    <div>
      <TitleServices
        title="Modelado en 3D"
        text="El modelado en 3D es una herramienta poderosa que permite visualizar de manera precisa el resultado final de un proyecto antes de que comience la instalación o fabricación. Esta tecnología no solo facilita la planificación y el diseño, sino que también brinda a los clientes una visión clara de cómo se integrarán los diferentes componentes en sus procesos industriales."
        image={image}
      />
      <SectionWithImageAndList
        title="¿Qué ofrecemos?"
        points={points}
        image={image2}
      />
      <SectionImageLeftTextRight
        title="Beneficios para su empresa"
        points={points2}
        image={image3}
      />
    </div >
  );
};

export default HeaderModelado3D;