// ImageCarousel.js
import React from 'react';
import '../assets/styles/ImageCarouselv2.css';

const slides = [
    {
      title: "Productos",
      subtitle: "Medicos",
      image:
        "https://ingemancr.com/wp-content/uploads/2019/03/01-Productos-M%C3%A9dicos-3-1024x576.jpg"
    },
    {
      title: "Planta de",
      subtitle: "Tratamiento",
      image:
        "https://ingemancr.com/wp-content/uploads/2019/03/Planta-de-tratamiento-1024x576.jpg.webp"
    },
    {
      title: "Paneles de",
      subtitle: "Control",
      image:
        "https://ingemancr.com/wp-content/uploads/2019/03/03-Paneles-de-Control--1024x576.jpg.webp"
    },
    {
      title: "Hidratador de ",
      subtitle: "Harina",
      image:
        "https://ingemancr.com/wp-content/uploads/2019/03/Rapido-Jet-Hidratador-de-Harina-6-1024x576.jpg.webp"
    },
  ];

function useTilt(active) {
    const ref = React.useRef(null);
  
    React.useEffect(() => {
      if (!ref.current || !active) {
        return;
      }
  
      const state = {
        rect: undefined,
        mouseX: undefined,
        mouseY: undefined
      };
  
      let el = ref.current;
  
      const handleMouseMove = (e) => {
        if (!el) {
          return;
        }
        if (!state.rect) {
          state.rect = el.getBoundingClientRect();
        }
        state.mouseX = e.clientX;
        state.mouseY = e.clientY;
        const px = (state.mouseX - state.rect.left) / state.rect.width;
        const py = (state.mouseY - state.rect.top) / state.rect.height;
  
        el.style.setProperty("--px", px);
        el.style.setProperty("--py", py);
      };
  
      el.addEventListener("mousemove", handleMouseMove);
  
      return () => {
        el.removeEventListener("mousemove", handleMouseMove);
      };
    }, [active]);
  
    return ref;
  }

const initialState = {
    slideIndex: 0
  };
const slidesReducer = (state, event) => {
    if (event.type === "NEXT") {
      return {
        ...state,
        slideIndex: (state.slideIndex + 1) % slides.length
      };
    }
    if (event.type === "PREV") {
      return {
        ...state,
        slideIndex:
          state.slideIndex === 0 ? slides.length - 1 : state.slideIndex - 1
      };
    }
  };
  

function Slide({ slide, offset }) {
    const active = offset === 0 ? true : null;
    const ref = useTilt(active);
    return (
        <div
            ref={ref}
            className="slide"
            data-active={active}
            style={{
                "--offset": offset,
                "--dir": offset === 0 ? 0 : offset > 0 ? 1 : -1
            }}
        >
            <div
                className="slideBackground"
                style={{
                    backgroundImage: `url('${slide.image}')`
                }}
            />
            <div
                className="slideContent"
                style={{
                    backgroundImage: `url('${slide.image}')`
                }}
            >
                <div className="slideContentInner">
                    <h2 className="slideTitle">{slide.title}</h2>
                    <h3 className="slideSubtitle">{slide.subtitle}</h3>
                    <p className="slideDescription">{slide.description}</p>
                </div>
            </div>
        </div>
    );
}

function ImageCarouselv2() {
    const [state, dispatch] = React.useReducer(slidesReducer, initialState);
    return (
        <div className="slides">
            <button onClick={() => dispatch({ type: "NEXT" })}>‹</button>

            {[...slides, ...slides, ...slides].map((slide, i) => {
                let offset = slides.length + (state.slideIndex - i);
                return <Slide slide={slide} offset={offset} key={i} />;
            })}
            <button onClick={() => dispatch({ type: "PREV" })}>›</button>
        </div>
    );
}

export default ImageCarouselv2;
