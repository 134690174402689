import React from "react";
import "../assets/styles/Modelado3D.css";

const TitleServices = ({ title, text, image }) => {
    return (
      <div className="header-modelado3d-container">
        <div className="text-content">
          <h1>{title}</h1>
          <div className="underline"></div>
          <p>{text}</p>
        </div>
        <img src={image} alt="Ejemplo de modelado en 3D" className="header-image" />
      </div>
    );
  };
  
  export default TitleServices;