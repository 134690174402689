// AboutUs.js
import React from 'react';
import '../assets/styles/AboutUs.css';


const AboutUs = () => {
  return (
    <section id="about" className="about-us">
      <h1 className="title">¿Quiénes somos?</h1>
      <p className="description">
        Nuestra experiencia en la integración de equipos de control y sistemas automatizados de alta calidad nos permite modernizar y mejorar los procesos de nuestros clientes, ayudándolos a alcanzar niveles superiores de productividad y competitividad.
      </p>
      
      <div className="section">
        <h2>Visión</h2>
        <p>Ser líderes en la modernización de procesos industriales, ayudando a empresas de diferentes sectores a adaptarse a las demandas del mercado y a implementar tecnologías que impulsen su crecimiento.</p>
      </div>

      <div className="section">
        <h2>Misión</h2>
        <p>Nuestra misión es proporcionar soluciones de automatización personalizadas que optimicen la eficiencia de las líneas de producción, garantizando calidad, seguridad y durabilidad en cada proyecto que emprendemos.</p>
      </div>

      <div className="section">
        <h2>Valores</h2>
        <ul>
          <li>Innovación</li>
          <li>Calidad</li>
          <li>Compromiso</li>
          <li>Atención personalizada</li>
        </ul>
        <p>Son los pilares que guían cada uno de nuestros proyectos.</p>
      </div>
    </section>
  );
};

export default AboutUs;
