import React from "react";
import "../assets/styles/Modelado3D.css";
import RomboideLayout from "./RomboideLayout";
import image from "../../src/assets/images/diagrama.png"; // Actualiza con la ruta de la primera imagen
import image2 from "../../src/assets/images/caja3.png"; // Actualiza con la ruta de la segunda
import image3 from "../../src/assets/images/repo.png"; // Actualiza con la ruta de la segunda
import image4 from "../../src/assets/images/migra.png"; // Actualiza con la ruta de la segunda
import image5 from "../../src/assets/images/auto2.png"; // Actualiza con la ruta de la segunda




const SeccionServicio = () => {
  return (
    <div style={{ marginTop: "120px" }}>
      <section id="services" className="services">
        <h1 className="title">Servicios</h1>
        <nav>
        <a href='/limpieza' style={{ textDecoration: "none" }}>
          <RomboideLayout
            romboideContent={{
              content1: "Limpieza y orden",
              content2: "de gabinetes",
            }}
            romboideBackWidth="600px"
            romboideBackHeight="120px"
            romboideImageUrl="https://blog.suileraltamirano.com/wp-content/uploads/2023/06/wed-e1685976436312.png"
            romboide2Content={{
              content1: "Asegure mantenimientos rápidos y eficientes.",
              content2: "Nosotros nos encargamos de mantener todo en",
              content3: "perfecto orden, facilitando las intervenciones y el",
              content4: "acceso a los componentes clave.",
            }}
          />
          </a>
          <a href='/diagramasE' style={{ textDecoration: "none" }}>
          <RomboideLayout
            romboideContent={{
              content1: "Diagramas",
              content2: "eléctricos",
            }}
            romboideBackWidth="600px"
            romboideBackHeight="120px"
            romboideImageUrl={image}
            romboide2Content={{
              content1: "Elaboramos diagramas eléctricos precisos y",
              content2: "detallados que facilitan la comprensión,",
              content3: "instalación y mantenimiento de los sistemas,",
              content4: " asegurando un flujo de trabajo eficiente y sin contratiempos.",
            }}
          />
          </a>
          <a href='/modelado3d' style={{ textDecoration: "none" }}>
          <RomboideLayout
            romboideContent={{
              content1: "Modelado en",
              content2: "3D",
            }}
            romboideBackWidth="600px"
            romboideBackHeight="120px"
            romboideImageUrl={image2}
            romboide2Content={{
              content1: "Transformamos su diseño en un modelo 3D para",
              content2: "ofrecerle una visión clara y detallada del",
              content3: "resultado final, facilitando la visualización y el",
              content4: "dimensionamiento preciso de cada aspecto del proyecto.",
            }}
          />
          </a>
          <a href='/repositorios' style={{ textDecoration: "none" }}>
          <RomboideLayout
            romboideContent={{
              content1: "Repositorios"
            }}
            romboideBackWidth="600px"
            romboideBackHeight="120px"
            romboideImageUrl={image3}
            romboide2Content={{
              content1: "Disponemos de repositorios con procesos",
              content2: "industriales comunes, que le permitirán",
              content3: "optimizar el tiempo de desarrollo de lógica PLC y el diseño de interfaces HMI asegurando",
              content4: "una implementación más rápida y eficiente.",
            }}
          />
          </a>
          <a href='/migraciones' style={{ textDecoration: "none" }}>
          <RomboideLayout
            romboideContent={{
              content1: "Migraciones"
            }}
            romboideBackWidth="600px"
            romboideBackHeight="120px"
            romboideImageUrl={image4}
            romboide2Content={{
              content1: "Actualizamos sus equipos a la última tecnología,",
              content2: "mejorando el rendimiento y agilizando sus",
              content3: "procesos para obtener resultados más eficientes",
              content4: "y productivos.",
            }}
          />
          </a>
          <a href='/automatizaciones' style={{ textDecoration: "none" }}>
          <RomboideLayout
            romboideContent={{
              content1: "Integraciones y",
              content2: "automatizaciones",
            }}
            romboideBackWidth="600px"
            romboideBackHeight="120px"
            romboideImageUrl={image5}
            romboide2Content={{
              content1: "Integramos equipos de control y sistemas",
              content2: "mecatrónicos para automatizar sus procesos,",
              content3: "aumentando el grado de autonomía y optimizando la eficiencia operativa."
            }}
          />
          </a>
        </nav>
      </section>
    </div>
  );
};

export default SeccionServicio;
